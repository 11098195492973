<template>
    <div class="dashboard">
        <router-link :event="item.disabled == '0' ? 'click' : ''" v-for="(item,index) in quizy" :key="index" :to="'/' + item.type + '/' + item.id" :class="item.classname">
            {{ item.name }}
            <span>{{ item.start_date_format }}</span>
        </router-link>

    </div>
</template>

<script>

    import { model } from "../model.js";

    export default {
        name: 'Dashboard',
        data() {
            return {
                'quizy': null
            }
        },
        mounted() {
            this.init();
        },
        methods: {

            init: async function() {
                var response = await model.getActive();

                /*
                if (response.data.status == 'Error')
                {
                    localStorage.removeItem('token');
                    this.$router.push({ name: 'Home' });
                    setTimeout(function() {
                        location.reload();
                    }, 100);

                    return false;
                }
                */

                this.quizy = response.data.results;

                for (var i in this.quizy)
                {
                    var active = this.quizy[i].isActive;
                    var success = this.quizy[i].isSuccess;

                    this.quizy[i].disabled = 0;

                    var classname = 'button is-block is-dark';

                    if (active == '0')
                    {
                        classname += ' is-disabled';
                    }

                    if (success == '1')
                    {
                        classname += ' is-disabled is-success';
                    }

                    this.quizy[i].classname = classname;

                    if (classname.indexOf('is-disabled') != -1)
                    {
                        this.quizy[i].disabled = 1;
                    }
                }
            },

        }
    }
</script>
